<template>
  <div class="row">
    <div class="col-lg-12">
      <div class="card card-custom">
        <div class="card-body">
          <div class="row">
            <div class="col-md-12">
              <div class="d-flex justify-content-end mt-2 p-2 bg-FFB803" style="height: 45px;">
                <button type="button" class="btn btn-primary btn-sm" @click="addServices">{{ $t('add_more') }}</button>
              </div>
            </div>

            <div class="col-md-12">
              <div class="bg-white">
                <table class="table table-row-bordered table-custom-padding" @keyup.alt.enter="addServices" @keyup.alt.46="removeRowServices(0)">
                  <thead>
                  <tr>
                    <th style="width:25%">{{ $t('item') }}</th>
                    <th style="width:25%">{{ $t('laundry_services_pricing.service_name') }}</th>
                    <th style="width:10%">{{ $t('categories.quantity') }}</th>
                    <th style="width:10%">{{ $t('laundry_services_pricing.price') }}</th>
                    <th style="width:15%">{{ $t('laundry_services_pricing.tax') }}</th>
                    <th style="width:15%">{{ $t('laundry_services_pricing.status') }}</th>
                    <th style="width:15%"></th>
                  </tr>

                  </thead>
                  <tbody>
                  <tr v-for="(row, index) in services" :key="index">
                    <td>
                      <multiselect v-model="row.item"
                                   :placeholder="$t('item')"
                                   label="name"
                                   track-by="id"
                                   :options="sub_item_list"
                                   :multiple="false"
                                   :taggable="false"
                                   @input="selectItem(index)"
                                   :show-labels="false"
                                   :show-no-options="false"
                                   :show-no-results="false">
                      </multiselect>
                      <span v-if="validation && validation[index+'.item']" class="fv-plugins-message-container invalid-feedback">
                                      {{ validation[index + '.item'][0] }}
                                    </span>

                    </td>
                    <td>
                      <multiselect v-model="row.laundry_service"
                                   :placeholder="$t('laundry_services_pricing.service_name')"
                                   label="name"
                                   track-by="id"
                                   :options="laundry_services"
                                   @input="selectLaundryServices(index)"
                                   :multiple="false"
                                   :taggable="false"
                                   :show-labels="false"
                                   :show-no-options="false"
                                   :show-no-results="false">
                      </multiselect>
                      <span v-if="validation && validation[index+'.laundry_service']" class="fv-plugins-message-container invalid-feedback">
                                      {{ validation[index + '.laundry_service'][0] }}
                                    </span>

                    </td>
                    <td>
                      <input type="number" step="1.0" v-model="row.qty" class="form-control" :class="validation && validation[index+'.qty'] ? 'is-invalid' : ''"/>
                      <span v-if="validation && validation[index+'.qty']" class="fv-plugins-message-container invalid-feedback">
                                      {{ validation[index + '.qty'][0] }}
                                    </span>
                    </td>
                    <td>
                      <input type="number" step="1.0" v-model="row.price" class="form-control" :class="validation && validation[index+'.price'] ? 'is-invalid' : ''"/>
                      <span v-if="validation && validation[index+'.price']" class="fv-plugins-message-container invalid-feedback">
                                      {{ validation[index + '.price'][0] }}
                                    </span>
                    </td>
                    <td>
                      <multiselect v-model="row.tax"
                                   :placeholder="$t('sales_invoices.tax')"
                                   label="name"
                                   track-by="id"
                                   :options="taxes"
                                   @input="selectLaundryTax(index)"
                                   :multiple="false"
                                   :taggable="false"
                                   :show-labels="false"
                                   :show-no-options="false"
                                   :show-no-results="false">
                      </multiselect>
                      <span v-if="validation && validation[index+'.tax']" class="fv-plugins-message-container invalid-feedback">
                                      {{ validation[index + '.tax'][0] }}
                                    </span>
                    </td>


                    <td>
                      <b-form-checkbox size="lg" v-model="row.is_active" name="check-button" switch></b-form-checkbox>
                    </td>
                    <td>
                      <v-icon v-if="!row.category_id" style="color: #dc3545;" small @click="removeRowServices(index)">mdi-delete</v-icon>
                    </td>
                  </tr>
                  </tbody>
                </table>

              </div>
            </div>

          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ApiService from "@/core/services/api.service";

export default {
  name: "LaundryServices",
  props:{
    mainItemId: null,
    taxes: [],
    servicesList: [],
  },
  data(){
    return{
      mainRouteDependency: 'base/dependency',

      /**
       * laundry service data
       */
      service_from: {id: null, item_id: null, item: null, laundry_service: null, laundry_service_id: null, price: null, qty: 0, is_active: null, tax_id: null, tax: null},
      services: [],
      laundry_services: [],
      sub_item_list: [],

      validation: null,
    }
  },
  watch:{

  },
  methods:{
    getSubItemList() {
      if (this.mainItemId)
        ApiService.get(`${this.mainRouteDependency}/group_items`,{params:{id: this.mainItemId}}).then((response) => {
          this.sub_item_list = response.data.data;
        });
    },
    getLaundryServices() {
      ApiService.get(`${this.mainRouteDependency}/laundry_services`).then((response) => {
        this.laundry_services = response.data.data;
      });
    },

    /**
     *
     * Laundry Services functions
     */

    addServices() {
      this.services.unshift(this.service_from);
      this.service_from = {id: null, item_id: null, item: null, laundry_service: null, laundry_service_id: null, price: null, qty: 0, is_active: null, tax_id: null, tax: null};
    },

    removeRowServices(index) {
      this.services.splice(index, 1);
    },

    selectItem(index){
      if (this.services[index].item && this.services[index].item.id){
        this.services[index].item_id = this.services[index].item.id;
      }
    },
    selectLaundryServices(index){
      if (this.services[index].laundry_service && this.services[index].laundry_service.id){
        this.services[index].laundry_service_id = this.services[index].laundry_service.id;
      }
    },
    selectLaundryTax(index){
      if (this.services[index].tax && this.services[index].tax.id){
        this.services[index].tax_id = this.services[index].tax.id;
      }
    },
  },
  created() {
    this.services = this.servicesList;

    this.getSubItemList();
    this.getLaundryServices();
  }
}
</script>

<style scoped>

</style>